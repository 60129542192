<template>
    <div id="restaurant-edit-template">
        <template v-if="restaurant">
            <div class="form">
                <div class="row">
                    <div class="element">
                        <div class="label bold">{{ restaurant.fiscal_name }}</div>
                        <div class="label bold">{{ restaurant.cif }}</div>
                        <div class="label bold">{{ restaurant.fiscal_address }}</div>
                    </div>
                </div>
                <div class="row">
                    <div class="element">
                        <div class="label">{{ $t('restaurants.alternative_address') }}</div>
                        <div class="input">
                            <textarea type="text" v-model="alternative_address" class="input-text textarea"></textarea>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <div class="action-buttons">
            <div class="export-button" @click="saveRestaurant()">
                {{ $t('restaurants.title_edit') }}
            </div>
            <div class="cancel" @click="goBack()">
                {{ $t('access.turn_back') }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RestaurantEdit',
    computed: {
        restaurant() {
            const restaurantId = this.$route.params['id']
            const restaurant = this.$store.getters['restaurant/getRestaurants'](restaurantId)
            return restaurant
        }
    },
    data() {
        return {
            alternative_address: ''
        }
    },
    methods: {
        validateRestaurant() {
            const restaurantId = this.$route.params['id']
            const restaurant = this.$store.getters['restaurant/getRestaurants'](restaurantId)

            if (typeof restaurant === 'undefined') {
                this.goBack()
            }

            this.alternative_address = restaurant.alternative_address
        },
        saveRestaurant() {
            const self = this
            const params = {
                id: this.$route.params['id'],
                alternative_address: this.alternative_address
            }

            console.log(this.alternative_address)

            this.$overlay.show()
            this.$store
                .dispatch('restaurant/edit', params)
                .then(() => {
                    self.$overlay.hide()
                    self.goBack()
                })
                .catch(() => {
                    self.$overlay.hide()
                    self.goBack()
                })
        },
        goBack() {
            this.$router.push({ name: 'Restaurants' })
        }
    },
    created() {
        const restaurants = this.$store.getters['restaurant/getRestaurants']()

        if (Object.keys(restaurants).length == 0) {
            const self = this
            this.$overlay.show()
            this.$store.dispatch('restaurant/loadRestaurants').then(function () {
                self.$overlay.hide()
                self.validateRestaurant()
            })
        } else {
            this.validateRestaurant()
        }
    }
}
</script>

<style lang="scss">
#restaurant-edit-template {
    width: 100%;
    height: calc(100% - 60px);
    padding-top: 35px;

    .form {
        height: 100%;
        .row {
            width: 100%;
            margin-bottom: 20px;

            .element {
                width: 50%;

                .label {
                    &.bold {
                        font-weight: bold;
                    }
                }

                .input-text {
                    border: 2px solid #bebebe;
                    border-radius: 4px;
                    font-family: Speedee-lt;
                    padding: 5px;
                    margin-top: 20px;
                    height: 60px;
                    width: 100%;
                    max-width: 600px;

                    &.error {
                        border: 2px solid $red;
                        background-color: $red2;
                    }

                    &.disabled {
                        opacity: 0.6;
                        cursor: not-allowed;
                    }
                }

                .textarea {
                    height: 160px !important;
                    resize: none;
                }
            }
        }
    }

    .action-buttons {
        display: flex;

        .export-button {
            background-color: $yellow;
            width: fit-content;
            padding: 8px 20px;
            height: fit-content;
            font-family: Speedee-lt;
            align-self: flex-end;
            font-size: 16px;
            border-radius: 4px;
            cursor: pointer;
            margin-right: 16px;

            &:hover {
                background-color: #e7a809;
            }
        }
        .cancel {
            background-color: #ddd;
            width: fit-content;
            padding: 8px 20px;
            height: fit-content;
            font-family: Speedee-lt;
            align-self: flex-end;
            font-size: 16px;
            border-radius: 4px;
            cursor: pointer;
            color: #333;

            &:hover {
                background-color: #9e9e9e;
            }
        }
    }
}
</style>
